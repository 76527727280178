<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <div
          class="d-flex justify-content-center mb-3"
          v-if="ProgessStart == 1"
        >
          <b-spinner
            variant="primary"
            type="grow"
            label="We are fetching details, please wait..."
          ></b-spinner>
        </div>

        <v-container>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  OK
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <br />

          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <br />
              <v-btn color="blue" class="mr-4" @click="refreshPageData">
                Refresh
              </v-btn>
              <p></p>
            </v-col>
          </v-row> -->

          <v-container class="py-0" v-if="ErrorMessageTxtFlag == 1">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">{{ ErrorMessageTxt }}</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-dialog v-model="editRecordPrompt" max-width="85%">
            <member-edit
              pageDescription="Edit Member Details"
              :editRecordPrompt="editRecordPrompt"
              @hideEditRecordPrompt="hideEditRecordPrompt"
              :recordIndex="recordToEdit"
              v-if="editRecordPrompt"
            ></member-edit>
          </v-dialog>

          <v-container class="py-0">
            <v-form ref="form" v-model="valid" lazy-validation id="createForm">
              <v-row wrap>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="JciYearCodeOptions"
                    :rules="JciYearCodeRules"
                    v-model="JciYearCode"
                    label="Year"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="YearHalfOptions"
                    :rules="YearHalfRules"
                    v-model="YearHalf"
                    label="Half"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="ZoneCodeOptions"
                    :rules="ZoneCodeRules"
                    v-model="ZoneCode"
                    label="Zone"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="LomCodeOptions"
                    :rules="LomCodeRules"
                    v-model="LomCode"
                    label="LOM"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                    :reduce="(option) => option.value"
                    :items="MemberTypeOptions"
                    :rules="MemberTypeRules"
                    v-model="MemberTypeCode"
                    label="Member Type"
                    dense
                    clearable
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid"
                    color="success"
                    class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                    @click="searchForm"
                  >
                    Search
                  </v-btn>
                  <v-btn
                    v-if="tableData1.length > 0"
                    color="error"
                    class="btn btn-primary font-size-h6 py-4 my-3 mr-3 white--text"
                    @click="deleteConfirmAlert"
                  >
                    Delete
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container
            class="py-0"
            v-if="tableData1.length == 0 && ProgessStart == 0"
          >
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h3 class="red--text">No records found.</h3>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0" v-if="tableData1.length > 0">
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  v-if="DownloadFlag"
                  color="info"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      ExcelFields,
                      ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="mr-4"
                  >Download</v-btn
                >
              </v-col>
              <p></p>
            </v-row>
            <br />
            <h3>Members List</h3>
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <h4>{{ tableData1.length }} records found.</h4>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  v-model="selected1"
                  :search="search"
                  :item-key="tableOptions1.ItemKey"
                  :single-select="tableOptions1.SingleSelectFlag"
                  :show-select="tableOptions1.ShowSelectFlag"
                  :headers="tableColumns1"
                  :items="tableData1"
                  :items-per-page="tableOptions1.ItemsPerPage"
                  :footer-props="tableOptions1.FooterProps"
                >
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.MemberImagePath="{ item }">
                    <img width="100" height="100" :src="item.MemberImagePath" />
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-search
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="warning"
                          @click="editAlert(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-edit
                        </v-icon>
                      </template>
                      <span> Edit </span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="RoleId == 2">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="purple"
                          @click="editMlDateAlert(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-calendar-clock
                        </v-icon>
                      </template>
                      <span> Edit ML Date </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          <br />
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog v-model="editDateRecordPrompt" persistent max-width="50%">
      <member-ml-date-edit
        :editDateRecordPrompt="editDateRecordPrompt"
        @hideEditDateRecordPrompt="hideEditDateRecordPrompt"
        :recordData="selectedData"
        v-if="editDateRecordPrompt"
      ></member-ml-date-edit>
    </v-dialog>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import MemberEdit from "@/view/pages/members/MemberEdit.vue";
import MemberMlDateEdit from "@/view/pages/erp/members/MemberMlDateEdit.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    MemberEdit,
    MemberMlDateEdit,
  },
  data() {
    return {
      search: "",
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid: true,

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],

      YearHalfRules: [(v) => !!v || "Half is required"],
      YearHalf: "",
      YearHalfOptions: [
        { value: "First", text: "First Half" },
        { value: "Second", text: "Second Half" },
      ],

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],

      MemberTypeRules: [(v) => !!v || "Member Type is required"],
      MemberTypeCode: "",
      MemberTypeOptions: [
        { value: 1, text: "JCI Member" },
        { value: 9, text: "Junior JCI Member" },
        { value: 2, text: "Associate Members" },
      ],

      MemberNameRules: [(v) => !!v || "Name is required"],
      MemberEmailRules: [],
      MemberMobileNoRules: [],
      MemberAddressRules: [],
      MemberCityRules: [],

      totalActiveAwards: 0,
      totalPendingAwards: 0,

      lastDate: "15-JAN-2021 11:59 PM",

      RoleId: "",

      ResultFlag: 0,
      BtnShowFlag: 0,

      NewMembers: [],

      bgColor: "#778899",
      position: "top-right",
      fabActions: [
        /*
        {
          name: 'deleteBtn',
          icon: 'delete',
          color: 'red',
        },
        */
        {
          name: "previewBtn",
          icon: "search",
          color: "orange",
        },
      ],

      singleSelect: true,
      selected1: [],
      selected2: [],
      search: "",
      search2: "",
      loading: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
      editDateRecordPrompt: false,

      recordToEdit: "",
      selectedData: {},

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      rows: [],
      tableData1: [],
      tableOptions1: [],
      tableColumns1: [],
      TotalMembers: 0,

      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        var roleId = this.$session.get("RoleId");
        roleId = roleId == (null || undefined) ? 0 : roleId;
        this.RoleId = roleId;
        console.log({ roleId });

        this.getJciYearCodeOptions();
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    rows: function () {
      console.log("watch rows");
      var rows = this.rows;
      var n1 = rows.length;
      var flag = n1 == 0 ? 0 : 1;
      console.log("n1=" + n1 + ", flag=" + flag);
      this.ResultFlag = flag;
      if (n1 > 0) {
        var filter1 = {
          ActiveStatusTxt: "Active",
        };
        console.log("filter1=" + JSON.stringify(filter1));
        var records1 = rows;
        records1 = records1.filter(function (item) {
          for (var key in filter1) {
            if (item[key] === undefined || item[key] != filter1[key])
              return false;
          }
          return true;
        });
        var n2 = records1.length;
        console.log("n2=" + n2 + ", records1=" + JSON.stringify(records1));

        var filter2 = {
          ActiveStatusTxt: "Pending",
        };
        console.log("filter2=" + JSON.stringify(filter2));
        var records2 = rows;
        records2 = records2.filter(function (item) {
          for (var key in filter2) {
            if (item[key] === undefined || item[key] != filter2[key])
              return false;
          }
          return true;
        });
        var n3 = records2.length;
        console.log("n3=" + n3 + ", records2=" + JSON.stringify(records2));

        this.totalActiveAwards = n2;
        this.totalPendingAwards = n3;
      }
    },
    selected1: function () {
      console.log("watch selected1");
      this.calculateTotalMembers();
    },
    selected2: function () {
      console.log("watch selected2");
      this.calculateTotalMembers();
    },
    NewMembers: function () {
      console.log("watch NewMembers");
      this.calculateTotalMembers();
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LoadingFlag = false;
      this.LomCodeOptionsLoading = false;
    },
    JciYearCode: function () {
      console.log("watch JciYearCode");
      this.ZoneCode = "";
      this.LomCode = "";
      this.ZoneCode = [];
      this.LomCodeOptions = [];
      this.resetMessageTxt();
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
      this.getZoneCodeOptions();
    },
    YearHalf: function () {
      console.log("watch YearHalf");

      this.resetMessageTxt();
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    ZoneCode: function () {
      console.log("watch ZoneCode");

      this.LomCode = "";
      this.LomCodeOptions = [];
      this.resetMessageTxt();
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
      this.getLomCodeOptions();
    },
    LomCode: function () {
      console.log("watch LomCode");

      this.resetMessageTxt();
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
    MemberTypeCode: function () {
      console.log("watch MemberTypeCode");

      this.resetMessageTxt();
      this.selected1 = [];
      this.tableData1 = [];
      this.tableOptions1 = [];
      this.tableColumns1 = [];
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    rowSelected(tr) {
      console.log("rowSelected called");
      console.log("tr=" + JSON.stringify(tr));
    },
    calculateTotalMembers() {
      console.log("calculateTotalMembers called");
      var c1 = this.selected1.length;
      var c2 = this.selected2.length;
      var c3 = this.NewMembers.length;
      console.log("c1=" + c1 + ", c2=" + c2 + ", c3=" + c3);
      var TotalMembers = parseInt(c1) + parseInt(c2) + parseInt(c3);
      console.log("TotalMembers=" + TotalMembers);
      this.TotalMembers = TotalMembers;
    },
    hideAddRecordPrompt() {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    resetMessageTxt() {
      console.log("resetMessageTxt called");
      this.ErrorMessageTxt = 0;
      this.ErrorMessageTxtFlag = "";
      this.SuccessMessageTxtFlag = 0;
      this.SuccessMessageTxt = "";
      this.totalActiveAwards = 0;
      this.totalPendingAwards = 0;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        var id = tr.MemberId;
        // var id = 1;
        console.log("id=" + id);
        // var url = "/lom-single";
        var url = "/member-individual";
        var params = {
          id: id,
        };
        this.pageRedirect(params, url);
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    previewAlert(tr) {
      console.log("previewAlert called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.recordToEdit = 1;
        this.previewRecordPrompt = true;
        this.selected = tr.AwardsId;
      } else {
        var message = "Kindly select one record to preview";
        this.errorMessage(message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert(tr) {
      console.log("editAlert called");
      console.log({ tr });
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var id = tr.MemberId;
        console.log({ id });
        this.recordToEdit = id;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    editMlDateAlert(tr) {
      console.log("editMlDateAlert called");
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.selectedData = tr;
        this.editDateRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    hideEditDateRecordPrompt(flag) {
      console.log("hideEditDateRecordPrompt called");
      this.editDateRecordPrompt = false;
      console.log({ flag });
      if (flag) {
        this.searchForm();
      }
    },
    deleteAlert(tr) {
      console.log("deleteAlert called");
      console.log("tr=" + JSON.stringify(tr));
    },
    addNewRow(tableId) {
      console.log("addNewRow called");
      console.log("tableId=" + tableId);
      if (tableId == 1) {
        var n1 = this.AllDesignation.length;
        console.log("n1=" + n1);
        if (n1 < 5) {
          this.AllDesignation.push({
            year: "",
            level: "",
            position: "",
          });
        }
      }

      if (tableId == 2) {
        var n1 = this.NewMembers.length;
        console.log("n1=" + n1);
        if (n1 < 30) {
          this.NewMembers.push({
            MemberName: "",
            MemberEmail: "",
            MemberMobileNo: "",
            MemberAddress: "",
            MemberCity: "",
          });
        }
      }
    },
    deleteRow(tableId, index, tr) {
      console.log("deleteRow called");
      console.log(
        "tableId=" + tableId + ", index=" + index + ", tr=" + JSON.stringify(tr)
      );
      if (index > -1) {
        if (tableId == 1) {
          this.AllDesignation.splice(index, 1);
        }
        if (tableId == 2) {
          this.NewMembers.splice(index, 1);
        }
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "membership_list",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getJciYearCodeOptions() {
      console.log("getJciYearCodeOptions called");
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        LomCode: 3,
        ZoneCode: 2,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getContributionTypeIdOptions() {
      console.log("getContributionTypeIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ContributionTypeId";
        var selectbox1_destination = "ContributionTypeIdOptions";
        var selectbox1_url = "api/year-wise-contribution/type-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "Jci Year should not be empty";
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getZoneCodeOptions() {
      this.ZoneCodeOptions = [];
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    searchForm() {
      console.log("searchForm is called");
      this.resetMessageTxt();
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.searchResult();
      } else {
        this.ErrorMessageTxtFlag = 1;
        this.ErrorMessageTxt = "Kindy fill required fields and then submit";
        console.log("some errors occured");
      }
    },
    searchResult() {
      console.log("searchResult called");

      this.resetMessageTxt();

      var LomCode = this.LomCode;
      var ZoneCode = this.ZoneCode;
      var YearCode = this.JciYearCode;
      var YearHalf = this.YearHalf;
      var MemberTypeCode = this.MemberTypeCode;
      console.log(
        "ZoneCode" +
          ZoneCode +
          ", LomCode=" +
          LomCode +
          ", YearCode=" +
          YearCode +
          ", YearHalf=" +
          YearHalf +
          ", MemberTypeCode=" +
          MemberTypeCode
      );

      if (YearCode != "" && YearHalf != "" && MemberTypeCode != "") {
        this.getDuesPaidMembers();
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (YearHalf == "") {
          message += "Year half should not be empty. ";
        }
        if (MemberTypeCode == "") {
          message += "MemberType should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getDuesPaidMembers() {
      console.log("getDuesPaidMembers called");

      this.resetMessageTxt();
      this.selected1 = [];
      this.tableData1 = [];

      var LomCode = this.LomCode;
      var ZoneCode = this.ZoneCode;
      var YearCode = this.JciYearCode;
      var YearHalf = this.YearHalf;
      var MemberTypeCode = this.MemberTypeCode;
      console.log(
        "ZoneCode=" +
          ZoneCode +
          "LomCode=" +
          LomCode +
          ", YearCode=" +
          YearCode +
          ", YearHalf=" +
          YearHalf +
          ", MemberTypeCode=" +
          MemberTypeCode
      );

      if (
        ZoneCode != "" &&
        YearCode != "" &&
        YearHalf != "" &&
        MemberTypeCode != ""
      ) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/member-dues/member-lists";
        var upload = {
          UserInterface: 1,
          ZoneCode: ZoneCode,
          LomCode: LomCode,
          YearCode: YearCode,
          YearHalf: YearHalf,
          MemberType: MemberTypeCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var table_content = [];
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            table_content = response.data.table_content;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = table_content.TableOptions;

            thisIns.tableColumns1 = table_content.TableHeader;
            thisIns.tableOptions1 = options;
            if (flag == 1) {
              thisIns.tableData1 = records;

              thisIns.DownloadFlag = options.DownloadFlag;
              thisIns.ExcelFileName = options.ExcelFileName;
              thisIns.ExcelFields = options.ExcelFields;
            } else {
              console.log("error=" + output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
          });
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (YearHalf == "") {
          message += "Year half should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    deleteConfirmAlert() {
      this.resetMessageTxt();

      var LomCode = this.LomCode;
      var YearCode = this.JciYearCode;
      var YearHalf = this.YearHalf;
      console.log(
        "LomCode=" +
          LomCode +
          ", YearCode=" +
          YearCode +
          ", YearHalf=" +
          YearHalf
      );

      var selected = this.selected1;
      var n1 = selected.length;
      console.log("n1=" + n1 + ", selected=" + JSON.stringify(selected));

      if (LomCode != "" && YearCode != "" && YearHalf != "" && n1 > 0) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            var upload = {
              LomCode: LomCode,
              YearCode: YearCode,
              YearHalf: YearHalf,
              Members: selected,
            };
            this.deleteRecords(upload);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (LomCode == "") {
          message += "Lom should not be empty. ";
        }
        if (YearHalf == "") {
          message += "Year half should not be empty. ";
        }
        if (n1 == 0) {
          message += "Select the members. ";
        }
        this.toast("error", message);
      }
    },
    deleteRecords(upload) {
      console.log("deleteRecords called");

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/member-dues/destroy";
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      // thisIns.pageLoadingOn()
      thisIns.ProgessStart = 1;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          // thisIns.pageLoadingOff()
          thisIns.ProgessStart = 0;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.tableData1 = [];
            thisIns.getDuesPaidMembers();
            thisIns.refreshPageData();
            thisIns.sweetAlert("success", output, true);
          } else {
            thisIns.sweetAlert("error", output, false);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
        });
    },
    addItem() {
      console.log("addItem called");
      this.dialog = true;
    },
    initialize() {
      this.rows = [];
    },

    editItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialog = true;
    },

    deleteItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.rows.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.rows[this.editedIndex], this.editedItem);
      } else {
        this.rows.push(this.editedItem);
      }
      this.close();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
</style>
